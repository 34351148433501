import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';

// core components
const images = {
  max_large: require('assets/img/max_zoom_crop.jpg'),
  max_small: require('assets/img/_max_zoom.jpg'),
  charlie_large: require('assets/img/charlie_header.jpg'),
  charlie_small: require('assets/img/charlie_small.jpg'),
};

const contactButtonHandler = () => {
  const contactSection = document.querySelector('.contact-section');
  if (contactSection) {
    contactSection.scrollIntoView();
  }
};

function LandingPageHeader() {
  let pageHeader = React.createRef();

  return (
    <>
      <div className="page-header" ref={pageHeader}>
        <div className="filter" />
        <div className="img-container">
          <div
            className="overflow-hidden"
            style={{ width: '100%', position: 'relative', zIndex: 11 }}>
            <picture>
              <source media="(min-width: 600px)" srcset={images.max_large} />
              <source media="(max-width: 600px)" srcset={images.max_small} />
              <source srcset={images.max_small} />
              <img
                className="img-container--max"
                src={images.max_large}
                alt=""
              />
            </picture>
            <div className="center-container">
              <Container>
                <div className="motto">
                  <Button
                    className="btn btn-outline-neutral btn-lg"
                    color="white"
                    onClick={contactButtonHandler}
                    target="_blank">
                    <i className="fa fa-play" style={{ fontWeight: '500' }} />
                    Subdirektor - ERGO und Vertrieb AG
                  </Button>
                  <br />
                </div>
              </Container>
            </div>
          </div>

          <div className="mobile-placeholder">
            <Container>
              <div className="motto">
                <h3 className="description" style={{ fontWeight: '500' }}>
                  Ihre Konstante für Versicherungen seit über 40 Jahrens
                </h3>
                <br />
              </div>
            </Container>
          </div>
          <div
            className="overflow-hidden"
            style={{ width: '100%', position: 'relative' }}>
            <picture>
              <source
                media="(min-width: 600px)"
                srcset={images.charlie_large}
              />
              <source
                media="(max-width: 600px)"
                srcset={images.charlie_small}
              />
              <source srcset={images.charlie_small} />
              <img
                className="img-container--charlie"
                src={images.charlie_large}
                alt=""
              />
            </picture>
            <div className="center-container">
              <Container>
                <div className="motto">
                  <Button
                    className="btn btn-outline-neutral btn-lg"
                    color="white"
                    onClick={contactButtonHandler}
                    target="_blank">
                    <i className="fa fa-play" style={{ fontWeight: '500' }} />
                    schuberth-versicherungsmakler.de
                  </Button>
                  <br />
                </div>
              </Container>
            </div>
          </div>
        </div>
        <div className="content-center" style={{ zIndex: 100 }}>
          <Container>
            <div className="motto">
              <h3 className="description" style={{ fontWeight: '500' }}>
                Ihre Konstante für Versicherungen seit über 40 Jahrens
              </h3>
              <br />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
